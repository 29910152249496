import { getData } from './request.js'

export function getAlarmList() {
    return getData({
        url: '/mapi/alarm_notification/list',
        method: 'get'
    })
}

export function addAlarm(data) {
    return getData({
        url: '/mapi/alarm_notification/add',
        method: 'post',
        data: data
    })
}

export function updateAlarm(data) {
    return getData({
        url: '/mapi/alarm_notification/update',
        method: 'put',
        data: data
    })
}

export function deleteAlarm(data) {
    return getData({
        url: '/mapi/alarm_notification/delete',
        method: 'delete',
        data: data
    })
}

export function switchAlarm(data) {
    return getData({
        url: 'mapi/alarm_notification/switch',
        method: 'put',
        data: data
    })
}

